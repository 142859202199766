
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import t from "@common/src/i18n/t";
import FileUpload from "@/components/scope/file-upload.vue";
const base = namespace("base");
const learning = namespace("learning");
@Component({
  components: {
    FileUpload
  }
})
export default class ExamLevelEdit extends Vue {
  @base.Action getDictionaryList;
  @learning.Action createCertificationLevel;
  @learning.Action updateCertificationLevel;

  //计算属性
  get breadData() {
    return [
      { name: t("v210831.certification-level-not-symbol"), path: "/learning/exam/level-list" },
      { name: t("v210831.new-level") }
    ];
  }
  defaultBannerList: any = [];
  form: any = {
    levelName: "",
    issuingAgency: "",
    levelImage: "",
    status: 1
  };
  rules = {
    issuingAgency: [{ required: true, message: t("v210831.please-enter-the-organization"), trigger: "blur" }],
    levelImage: [{ required: true, message: t("v210831.please-select-level-sign"), trigger: "blur" }],
    levelName: [{ required: true, message: t("v210831.please-enter-the-level-name"), trigger: "blur" }],
    status: [{ required: true, message: t("v210831.please-select-a-status"), trigger: "change" }]
  };
  created() {
    this.init();
  }
  mounted() {}
  init() {
    console.log("[ this.$route.params.row ]", this.$route.params.row);
    if (this.$route.params.row) {
      let row = this.$route.params.row as any;
      this.form = this.$route.params.row;
      this.defaultBannerList = [{ url: row.levelImage as any }];
    }
  }
  handleBannerUploadSuccess(url) {
    (this.$refs.consultForm as any).validateField("bannerUrl");
  }
  async save(go) {
    if (go === -1) {
      this.$router.go(-1);
      return;
    }
    let refs = this.$refs as any;
    if (this.$route.params.row) {
      refs.form.validate(async valid => {
        if (valid) {
          const res = await this.updateCertificationLevel(this.form);
          if (res.success) {
            this.$message.success(t("base.update-success"));
            this.$router.go(-1);
          }
        }
      });
    } else {
      refs.form.validate(async valid => {
        if (valid) {
          const res = await this.createCertificationLevel(this.form);
          if (res.success) {
            this.$message.success(t("finance.new-success"));
            this.$router.go(-1);
          }
        }
      });
    }
  }
}
